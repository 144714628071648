import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import SubTitle from '../../components/subTitle/SubTitle';
import { Row, Col } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faStore } from '@fortawesome/free-solid-svg-icons';
import { faAward } from '@fortawesome/free-solid-svg-icons'
import { httpGet } from '../../http';
import './Certificados.css';

const Certificado = (props) => {
    return(
        <div style={{ marginBottom: '20px' }}>
            <div className='certificado-container'>
                <div className='certificado-icon-container'>
                    <FontAwesomeIcon className='view-icon' icon={faAward} />
                </div>
                <div>
                    <div className='certificado-title'>{ props.children }</div>
                </div>
            </div>
        </div>
    );
}

const Certificados = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: ''
        },
        loading: true,
        error: {}
    });

    const [ tipoRetenciones, setTipoRetenciones ] = useState([]);

    useEffect(() => {

        console.log(JSON.parse(sessionStorage.getItem('estado')));

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        console.log(newEstado);
        
        setEstado(newEstado);
        getTipoRetenciones();

    }, []);

    const getTipoRetenciones = async () => {
        const resp = await httpGet('/api/v1/retenciones/tipo/');
        if(resp){
            setTipoRetenciones(resp.data);
        }
    }

    const goToRetencion = (tipo) => {
        console.log(tipo);
        if(tipo.ciudad){

        } else {
            props.history.push({ pathname: '/portal/retencion1', state: { tipo: "tipo" } });
        }
    }

    return(
        <div className='page-container'>
            <Title>Certificados</Title>
            <div className='page-body'>
                <SubTitle>Empresa: { estado.empresa.razonSocial } </SubTitle>
                <SubTitle>Proveedor:</SubTitle>
                <div>
                    <div><span className='info-title'>N.I.T: </span><span>{ estado.proveedor.nit }</span></div>
                    <div><span className='info-title'>Razón social: </span><span>{ estado.proveedor.razonSocial }</span></div>
                </div>
                <br />
                <br />
                <SubTitle>Seleccione el tipo de certificado</SubTitle>
                <div>
                    <Row className='justify-content-md-center co-row'>
                        {
                            tipoRetenciones.map(
                                (tipo, index) => {
                                    return(
                                        <Col key={index} className='co-col' xs={12} md={6} lg={6} xl={4} >
                                            { tipo.ciudad ?
                                                <Link to={{ pathname: '/portal/certificado-ciudades', state: { tipo: tipo } }}><Certificado>{tipo.descripcion}</Certificado></Link>
                                            :
                                                <Link to={{ pathname: '/portal/retencion', state: { tipo: tipo } }}><Certificado>{tipo.descripcion}</Certificado></Link>
                                            }
                                        </Col>
                                    );
                                }
                            )
                        }                        
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default Certificados;