import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import SubTitle from '../../components/subTitle/SubTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { httpGet } from '../../http';
import { formatNumber, getYear, getCurrentDate, getExpeditionDateByYear, getExpeditionDateByPeriod } from '../../misc';
import './RetencionDetalle.css';
import { Link } from 'react-router-dom';
import { Alert, Spinner } from 'react-bootstrap';
import Button from '../../components/button/Button';

const Fecha = (props) => {
    return(
        <div className='fecha-container'>
            <div>{props.label}</div>
            <input type="date" id="fecha" name={props.name} value={props.value} onChange={props.onChange} />
        </div>
    );
}

const RetencionDetalle = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: '',
            digitoVerificacion: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: ''
        },
        loading: true,
        error: {}
    });

    const [ tipo, setTipo ] = useState({
        descripcion: ''
    });
    const [ rete, setRete ] = useState({
        anno: ''
    });
    const [retencion, setRetencion ] = useState(
        {
            empresa: {
                razonSocial: '',
                nit: '',
                dv: ''
            },
            proveedor: {
                codigo: '',
                deigitoVerificacion: '',
                descripcion: '',
                direccion: '',
                ciudad: ''
            },
            data: [],
            total: {
                base: 0,
                retencion: 0
            },
            tipo: {
                codigo: '',
                titulo: '',
                periodoGravable: '',
                ciudad: '',
                fechaDesde: '',
                fechaHasta: '',
                visible: false
            },
            loading: false,
            error: {}
        }
    );

    const [ request, setRequest ] = useState({
        loading: true,
        error: false,
        errorMsg: ''
    });

    useEffect(() => {

       
        console.log(JSON.parse(sessionStorage.getItem('estado')));

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        console.log(newEstado);
        
        setEstado(newEstado);

        getRetencion();
        
    }, []);

    const getRetencion = async () => {
        
        setRequest({
            ...request,
            loading: true,
            error: false,
            errorMsg: ''
        });

        try{
            const tipoRetencion = props.history.location.state.tipo;
            const rete = props.history.location.state.retencion;
            const proveedor = JSON.parse(sessionStorage.getItem("proveedor"));
            const newEstado = JSON.parse(sessionStorage.getItem('estado'));
            console.log(proveedor);
            console.log(rete);
            let resp = undefined;
            if(rete.periodo){
                resp = await httpGet('/api/v1/retenciones/detalle/periodo/' + tipoRetencion.cuenta + '/' + rete.id + '/' + proveedor.nit);
            } else if(rete.mes){
                resp = await httpGet('/api/v1/retenciones/detalle/mes/' + tipoRetencion.cuenta + '/' + rete.id + '/' + proveedor.nit);
            } else {
                resp = await httpGet('/api/v1/retenciones/detalle/anno/' + tipoRetencion.cuenta + '/' + props.history.location.state.retencion.anno + '/' + proveedor.nit);
            }
            const respTercero = await httpGet('/api/v1/proveedor/tercero/' + proveedor.nit);
            if(resp){
    
                let totalBase = 0;
                let totalRetencion = 0;
    
                for( const reg of resp.data){
                    totalBase = totalBase + reg.base;
                    totalRetencion = totalRetencion + reg.retencion;
                }
    
                const total = {
                    base: totalBase,
                    retencion: totalRetencion
                }
    
                const tipo = {
                    descripcion: tipoRetencion.descripcion,
                    periodoGravable: ( rete.periodo || rete.mes ) ? rete.descripcion + ' de ' + rete.anno : rete.anno,
                    ciudad: tipoRetencion.ciudadRetencion,
                    modo: rete.periodo ? true : false
    
                }
    
                const empresa = {
                    razonSocial: newEstado.empresa.razonSocial,
                    nit: newEstado.empresa.nit,
                    digitoVerificacion: newEstado.empresa.digitoVerificacion,
                    representanteLegal: newEstado.empresa.representanteLegal
                }
    
                setRetencion({
                    ...retencion,
                    empresa: empresa,
                    proveedor: respTercero.data[0],
                    data: resp.data,
                    total: total,
                    tipo: tipo,
                    fechaDeExpedicion: rete.periodo ? getExpeditionDateByPeriod(rete.id) : getExpeditionDateByYear(rete.anno)
                });
    
                setTipo(tipoRetencion);

                setRequest({
                    ...request,
                    loading: false,
                    error: false,
                    errorMsg: ''
                });
            }
        } catch(error) {
            setRequest({
                ...request,
                loading: false,
                error: error,
                errorMsg: ''
            });

            console.log(error);
        }
    }

    return(
        <div className='page-container'>
            <Title>{ props.history.location.state.tipo.descripcion }</Title>
            
            <div className='page-body'>

                <br/>
                { 
                    request.loading ?
                        <div className='loading-spinner'>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    : request.error ?
                        <div>
                            <Alert style={{ width: '100%', marginLeft: '40px', marginTop: '50px', marginBottom: '50px', textAlign: 'center' }} variant='success'>Ocurrio un error al cargar la información</Alert>
                        </div>
                    :
                    <>
                    <br/>
                    <br/>
                    <SubTitle>Certificado { tipo.descripcion } {estado.empresa.razonSocial} </SubTitle>
                    <div>
                        <div>{estado.empresa.razonSocial}</div>
                        <div>{estado.empresa.nit}-{estado.empresa.digitoVerificacion}</div>
                    </div>
                    <br />
                    <div>
                        CALI
                    </div>
                    <br />
                    <SubTitle>{ retencion.tipo.periodoGravable ? 'Periodo gravable:' : 'Año gravable:'} {retencion.tipo.periodoGravable}</SubTitle>
                    <div>
                        <div><span className='info-title'>Retenido: </span><span>{ retencion.proveedor.descripcion }</span></div>
                        <div><span className='info-title'>N.I.T.: </span><span>{ retencion.proveedor.codigo }-{ retencion.proveedor.digitoVerificacion }</span></div>
                        <div><span className='info-title'>Dirección </span><span>{ retencion.proveedor.direccion }</span></div>
                        <div><span className='info-title'>Ciudad </span><span>{ retencion.proveedor.ciudad }</span></div>
                        <br />
                        <br />
                        <div><span className='info-title'>Ciudad donde se practicó la retención: </span><span>{ retencion.tipo.ciudad }</span></div>
                        <div><span className='info-title'>Ciudad donde se consignó la retención: </span><span>{ retencion.tipo.ciudad }</span></div>
                    </div>
                    <br />
                    <table className='info-table tabla-retencion'>
                        <thead>
                            <tr>
                                <th>CONCEPTO</th>
                                <th>TASA</th>
                                <th>BASE</th>
                                <th>RETENCION</th>
                            </tr>
                        </thead>
                        <tbody>
                            { 
                                retencion.data.map(
                                    (rete, index) => {
                                        return(
                                            <tr key={index}>
                                                <td>{ rete.descripcionCuenta }</td>
                                                <td>{ rete.tasa }%</td>
                                                <td>${ formatNumber(rete.base) }</td>
                                                <td>${ formatNumber(rete.retencion) }</td>
                                            </tr>
                                        );
                                    }
                                )
                            }
                            <tr>
                                <td colSpan='2'>TOTAL</td>
                                <td>${ formatNumber(retencion.total.base) }</td>
                                <td>${ formatNumber(retencion.total.retencion) }</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <br />
                    <div>
                        <div className='retencion-footer-text'>Fecha de expedición { retencion.fechaDeExpedicion }</div>
                        < br/>
                        <div className='retencion-footer-text'>Fecha de generacion { getCurrentDate() }</div>
                        < br/>
                        <div>
                            <span>Descargar </span>
                            <span className='pdf-icon-container'><Link style={{ color: 'red' }} to={{ pathname: '/portal/certificado-pdf', state: { retencion: retencion } }}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></Link></span>
                        </div>
                    </div>
                    </>
                }
            </div>         
        </div>
    );
}

export default RetencionDetalle;
